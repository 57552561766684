import React from 'react';
import MuiCollapse, {
  CollapseProps as CollapseProperties,
} from '@mui/material/Collapse';
import MuiList, { ListProps as MUIListProperties } from '@mui/material/List';
import MuiListItem, {
  ListItemProps as MUIListItemProperties,
} from '@mui/material/ListItem';
import MuiListItemText, {
  ListItemTextProps as ListItemTextProperties,
} from '@mui/material/ListItemText';
import MuiListItemIcon, {
  ListItemIconProps as ListItemIconProperties,
} from '@mui/material/ListItemIcon';
import MuiListDivider, {
  DividerProps as DividerProperties,
} from '@mui/material/Divider';
import MuiListItemAvatar, {
  ListItemAvatarProps as ListItemAvatarProperties,
} from '@mui/material/ListItemAvatar';
import styled from '@emotion/styled';

const ListContainer = styled.div`
  max-width: 900px;
  margin: 0 auto;
`;

type ListProperties = Component.Properties<MUIListProperties & {
  resetpadding?: boolean;
}>;

const List = ({
  tag = 'ul',
  resetpadding = false,
  ...properties
}: ListProperties) => (
  <MuiList
    component={tag}
    disablePadding={resetpadding}
    {...properties}
  />
);

type ListItemProperties = Component.Properties<MUIListItemProperties>;

const ListItem = ({ tag = 'li', ...properties }: ListItemProperties) => (
  <MuiListItem
    component={tag}
    {...properties}
  />
);

const ListItemText = (
  properties: ListItemTextProperties,
) => <MuiListItemText {...properties} />;

const ListItemMeta = (
  properties: ListItemIconProperties,
) => <MuiListItemIcon {...properties} />;

type ListDividerProperties = Component.Properties<DividerProperties> & {
  hideIntroTextOnMobile?: boolean;
};

const ListDivider = ({
  tag = 'li', hideIntroTextOnMobile, ...properties
}: ListDividerProperties) => (
  <MuiListDivider
    component={tag}
    {...properties}
  />
);

const CollapsibleList = React.forwardRef<HTMLElement, CollapseProperties>(
  (properties, reference) => <MuiCollapse {...properties} ref={reference} />,
);

const ListItemAvatar = (
  properties: ListItemAvatarProperties,
) => <MuiListItemAvatar {...properties} />;

export {
  List,
  ListItem,
  ListItemText,
  ListItemMeta,
  ListItemAvatar,
  ListDivider,
  CollapsibleList,
  ListContainer,
};
