import invert from 'lodash/invert';
import messages from './messages.mjs';

export const categoryParametersToCategory = {
  'design-and-creative': 'Design & Creative',
  internships: 'Internships',
  other: 'Other',
  'product-and-supply-chain': 'Product & Supply Chain',
  'retail-management-and-in-store': 'Retail Management & In-store',
  'sales-and-marketing': 'Sales & Marketing',
};

export const categoryNames = Object.values(categoryParametersToCategory);

export const categoriesToCategoryParameters = invert(
  categoryParametersToCategory,
);

export const originalCategoryParameters = Object.keys(
  categoryParametersToCategory,
);

export const getLocalizedCategory = (
  intl, category,
) => intl.formatMessage(
  messages[`jobs.category.${categoriesToCategoryParameters[category]}.label`],
);

export const getLocalizedCategoryParameter = (
  intl, originalCategoryParameter,
) => intl.formatMessage(
  messages[`jobs.category.${originalCategoryParameter}.param`],
);

export const getLocalizedCategoryParameterByCategory = (
  intl, category,
) => intl.formatMessage(
  messages[`jobs.category.${categoriesToCategoryParameters[category]}.param`],
);

export const getLocalizedCategoryParameters = (
  intl,
) => Object.fromEntries(
  originalCategoryParameters
    .map((originalCategoryParameter) => [
      getLocalizedCategoryParameter(intl, originalCategoryParameter),
      originalCategoryParameter,
    ]),
);
