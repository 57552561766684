import { ParsedUrlQuery } from 'node:querystring';

const prefix = 'routerState';

const getSessionStorageKey = (key : string) => `${prefix}.${key}`;

export interface RouterState {
  route?: string;
  query?: ParsedUrlQuery;
}

export const saveRouterState = (key : string, state : RouterState) => (
  sessionStorage.setItem(getSessionStorageKey(key), JSON.stringify(state)));

export const getRouterState = (key : string) => {
  const value = sessionStorage.getItem(getSessionStorageKey(key));
  return typeof value === 'string' ? JSON.parse(value) : null;
};
