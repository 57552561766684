import React from 'react';
import MaterialUITypography, { TypographyProperties } from '../Typography';

const Headline3 = ({ tag, ...properties }: TypographyProperties) => (
  <MaterialUITypography
    tag={tag}
    variant="h3"
    {...properties}
  />
);

export default Headline3;
