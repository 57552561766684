import React from 'react';
import { useIntl } from 'react-intl';
import LinkAndAnchor from './LinkAndAnchor';
import { useSiteContext } from './SiteContext';
import messages from '../lib/messages.mjs';

const HomeLink = () => {
  const intl = useIntl();
  const { hasNews, lite } = useSiteContext();

  return (
    <LinkAndAnchor
      key="home"
      route="index"
      passHref
      useRouter={hasNews || lite}
    >
      {intl.formatMessage(messages.home)}
    </LinkAndAnchor>
  );
};

export default HomeLink;
